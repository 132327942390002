// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lets-work-together-bespoke-originals-jsx": () => import("./../../../src/pages/lets-work-together/bespoke-originals.jsx" /* webpackChunkName: "component---src-pages-lets-work-together-bespoke-originals-jsx" */),
  "component---src-pages-lets-work-together-commercial-commissions-jsx": () => import("./../../../src/pages/lets-work-together/commercial-commissions.jsx" /* webpackChunkName: "component---src-pages-lets-work-together-commercial-commissions-jsx" */),
  "component---src-pages-lets-work-together-gifting-experience-jsx": () => import("./../../../src/pages/lets-work-together/gifting-experience.jsx" /* webpackChunkName: "component---src-pages-lets-work-together-gifting-experience-jsx" */),
  "component---src-pages-lets-work-together-index-jsx": () => import("./../../../src/pages/lets-work-together/index.jsx" /* webpackChunkName: "component---src-pages-lets-work-together-index-jsx" */),
  "component---src-pages-portfolio-inspire-me-jsx": () => import("./../../../src/pages/portfolio/inspire-me.jsx" /* webpackChunkName: "component---src-pages-portfolio-inspire-me-jsx" */)
}

